import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, desktopBreakpoint, mobileVW, font } from '../../../styles'

// Components
import RichText from '../../shared/RichText'

const Guide = ({ content, label }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <GuidePopup className={`${isOpen ? 'open' : ''}`}>
        <GuideCloseButton onClick={() => setIsOpen(false)} type='button' />
        <RichText json={content.json} />
      </GuidePopup>

      <GuideOpenButton onClick={() => setIsOpen(true)} type='button'>
        {label}
      </GuideOpenButton>
    </>
  )
}

const GuidePopup = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  width: 200px;
  padding: 21px 22px;
  background: #e5edfe;
  z-index: 16;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px hsla(0, 0%, 0%, 0.1);
  transform: translateY(-50%);

  &.open {
    display: block;
  }

  h3,
  h3 * {
    font-size: 22px !important;
    margin-bottom: 10px !important;
    font-family: ${font.ben};
    line-height: 21px;
    text-transform: lowercase;
    font-weight: normal;
  }

  li:not(:last-child) {
    margin-bottom: 1em;
  }

  p {
    font-family: ${font.larsseit};
    font-size: 11px;
  }

  b {
    font-family: ${font.ben};
    text-transform: lowercase;
    font-size: 13px;
    font-weight: normal;
  }
`

const GuideCloseButton = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
  width: 20px;
  height: 20px;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 9px;
    height: 2px;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background: #000000;
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const GuideOpenButton = styled.button`
  font-size: ${mobileVW(14)};
  font-family: ${font.larsseit};
  font-weight: normal;
  text-decoration: underline;
  color: ${colors.brownDarkest};
  margin-left: ${mobileVW(15)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-left: 15px;
    font-size: 14px;
  }
`
export default Guide
