import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors, desktopBreakpoint, mobileVW, font } from '../../../styles'

// Hooks
import useDictionaryCtx from '../../../hooks/context/useDictionaryCtx'

// Components
import OptionGuide from './Guide'

// =============== //
// ++ Helper(s) ++ //
// =============== //

function getOptionsFromVariants(variants) {
  const options = []

  if (variants.length == 1) return []

  const parsedOptions = variants.reduce((acc, cur) => {
    const variantOptions = cur.options

    Object.keys(variantOptions).forEach(key => {
      if (!(key in acc)) acc[key] = new Set()

      acc[key].add(variantOptions[key])
    })

    return acc
  }, {})

  Object.keys(parsedOptions).forEach(key => {
    options.push({
      name: key,
      values: [...parsedOptions[key]],
    })
  })

  return options.map(({ name, values }) => ({
    name,
    values: values.map(value => ({
      value,
      availableIn: variants.reduce((acc, cur) => {
        if (cur.options[name] === value && cur.available) return acc + 1
        return acc
      }, 0),
    })),
  }))
}

const Options = React.forwardRef(({ variants, onChange, guides }, ref) => {
  const dictionary = useDictionaryCtx()

  const [options, setOptions] = useState([])
  const [optionNames, setOptionNames] = useState([])
  const [selectedOptions, setSelectedOptions] = useState({})

  useEffect(() => {
    if (!optionNames.length) return

    const variant = variants.find(variant =>
      optionNames.every(name => variant.options[name] == selectedOptions[name]),
    )

    onChange(variant || null)
  }, [selectedOptions, optionNames])

  useEffect(() => {
    const newOptions = getOptionsFromVariants(variants)
    const newOptionNames = newOptions.map(({ name }) => name)

    setOptions(newOptions)
    setOptionNames(newOptionNames)

    setSelectedOptions(
      Object.assign({}, ...newOptionNames.map(name => ({ [name]: null }))),
    )
  }, [variants])

  const handleChange = e => {
    const { name, value, checked } = e.target

    setSelectedOptions({ ...selectedOptions, [name]: checked ? value : null })

    if (ref && ref.current) {
      ref.current.classList.remove('error')
    }
  }

  return (
    <>
      <OptionsWrapper ref={ref}>
        {options.map(({ name, values }, i) => (
          <React.Fragment key={i}>
            <OptionsName>{name}*</OptionsName>

            <OptionsListContainer
              className={`${selectedOptions[name] ? 'selected' : ''}`}
            >
              <OptionsList>
                {values.map(({ value, availableIn }) => {
                  const key = `${name}_${value}`

                  return (
                    <OptionContainer key={key}>
                      <OptionInput
                        id={key}
                        name={name}
                        checked={selectedOptions[name] == value}
                        type='checkbox'
                        value={value}
                        onChange={handleChange}
                        disabled={availableIn === 0}
                      />
                      <OptionLabel htmlFor={key}>{value}</OptionLabel>
                    </OptionContainer>
                  )
                })}
              </OptionsList>

              {/* Size guide, color guide, etc */}
              {guides[name] && guides[name].label && guides[name].content && (
                <OptionGuide
                  label={guides[name].label}
                  content={guides[name].content}
                />
              )}
            </OptionsListContainer>
          </React.Fragment>
        ))}
      </OptionsWrapper>
      {options.length > 0 && (
        <OptionsFooterText
          allSelected={optionNames.every(
            name => selectedOptions[name] !== null,
          )}
        >
          *{dictionary.pleaseSelect}
        </OptionsFooterText>
      )}
    </>
  )
})

const errorFlash = keyframes`
  to {
    color: ${colors.red};
    border-color: ${colors.red};
  }
`

const OptionsWrapper = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: ${mobileVW(12)};
  margin: 0 0 ${mobileVW(14)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 29px 0 13px;
    grid-gap: 12px;
  }

  &.error + strong {
    animation: ${errorFlash} 1s ease alternate-reverse;
  }

  &.error dd:not(.selected) ul li input:not(:disabled) + label {
    animation: ${errorFlash} 1s ease alternate-reverse;
  }
`

const OptionsName = styled.dt`
  font-size: 15px;
  font-family: ${font.larsseit};
  font-weight: normal;

  &.disable,
  &.disable + dd {
    opacity: 0.5;
    pointe-events: none;
  }
`

const OptionsListContainer = styled.dd`
  margin: 0;
  display: flex;
  position: relative;
`

const OptionsList = styled.ul`
  list-style: none;
  display: flex;
`

const OptionContainer = styled.li`
  height: 35px;
  width: 35px;
  position: relative;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

const OptionInput = styled.input`
  height: 0;
  width: 0;
  pointer-events: none;
  opacity: 0;
  visibility: none;
  overflow: hidden;

  &:checked + label {
    background: ${colors.beigeUltraLight};
  }

  &:focus + label::after {
    content: '';
    display: block;
    position: absolute;
    background: none;
    border: inherit;
    transform: scale(1.4);
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    border-radius: inherit;
    box-sizing: border-box;
  }

  &:disabled + label {
    pointer-events: none;
    opacity: 0.5;
  }
`

const OptionLabel = styled.label`
  border-radius: 100%;
  border: 2px solid ${colors.beigeUltraLight};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 12px;
  font-family: ${font.ben};
  text-transform: lowercase;
  color: ${colors.brownDark};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }
`

const OptionsFooterText = styled.strong`
  font-family: ${font.larsseit};
  font-weight: normal;
  color: ${({ allSelected }) => (allSelected ? '#9B9B9B' : colors.orange)};
  display: block;
  margin-bottom: ${mobileVW(24)};
  font-size: ${mobileVW(15)};
  line-height: ${mobileVW(18)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 19px;
    font-size: 15px;
    line-height: 18px;
  }
`

export default Options
