import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { mobileVW, font, desktopBreakpoint } from '../../styles'
import 'swiper/swiper.min.css'

// Hooks
import useDictionaryCtx from '../../hooks/context/useDictionaryCtx'

// Components
import ProductThumbnail from '../collection/ProductThumbnail'

SwiperCore.use([Navigation])

const RelatedProducts = ({ relatedProducts }) => {
  const { relatedProductsTitle } = useDictionaryCtx()

  return (
    <Wrapper>
      <Title>{relatedProductsTitle}</Title>
      <Swiper
        initialSlide={0}
        spaceBetween={5}
        slidesPerView={'auto'}
        watchSlidesVisibility={true}
        breakpoints={{
          1024: {
            spaceBetween: 40,
          },
        }}
      >
        {relatedProducts &&
          relatedProducts.map(
            ({
              title,
              imageThumbnail,
              slug,
              productDescriptionShort,
              shopifyProduct,
              highlightTitle,
              id,
              imageThumbnailOnHover,
            }) => (
              <SwiperSlide key={slug}>
                <ProductThumbnail
                  image={imageThumbnail}
                  title={title}
                  description={productDescriptionShort}
                  shopifyProduct={shopifyProduct}
                  slug={slug}
                  highlightTitle={highlightTitle}
                  id={id}
                  staticSize={true}
                  hoverImage={imageThumbnailOnHover}
                />
              </SwiperSlide>
            ),
          )}
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: ${mobileVW(66)};
  margin-bottom: ${mobileVW(39)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 80px;
    margin-top: 74px;
  }

  .swiper-container {
    padding: 0 20px;

    @media (min-width: ${desktopBreakpoint}) {
      padding: 0 56px;
    }

    .swiper-wrapper {
      width: fit-content;
      margin: 0 auto;
    }
  }

  .swiper-slide {
    max-width: 164px;
    height: auto !important;

    &:last-child {
      margin-right: 0 !important;
    }

    .thumbnail {
      height: 100%;

      .thumbnail__title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        min-height: 27px;

        @media (min-width: ${desktopBreakpoint}) {
          -webkit-line-clamp: 3;
        }
      }

      .thumbnail__description {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;

        @media (min-width: ${desktopBreakpoint}) {
          line-clamp: 2;
        }
      }

      .thumbnail__highlight {
        display: none;

        @media (min-width: ${desktopBreakpoint}) {
          display: block;
        }
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      max-width: 262px;
    }
  }
`

const Title = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  margin-bottom: ${mobileVW(35)};
  text-transform: lowercase;
  padding: 0 20px;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    padding: 0 56px;
    margin-bottom: 62px;
  }
`

export default RelatedProducts
