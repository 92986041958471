import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Hooks
import useShopCtx from './context/useShopCtx'

// Helpers
function getReviews(id) {
  const query = {
    method: 'get',
    url: `https://api.yotpo.com/v1/widget/${process.env.GATSBY_YOTPO_CLIENT_ID}/products/${id}/reviews.json`,
  }

  return new Promise((resolve, reject) => {
    ;(async function() {
      try {
        const response = await axios(query)

        resolve(response.data.response)
      } catch (error) {
        reject(error)
      }
    })()
  })
}

const useReviews = productId => {
  const { products } = useShopCtx()

  const [id, setId] = useState(null)

  const [reviews, setReviews] = useState({
    reviews: [],
    pagination: {
      total: 0,
    },
    bottomLine: null,
  })

  useEffect(() => {
    const reviewProductId = products.find(({ id }) => id === productId)
      ?.reviewProductId

    setId(reviewProductId || null)
  }, [products])

  useEffect(() => {
    async function fetchData() {
      try {
        const reviews = await getReviews(id)

        setReviews(reviews)
      } catch (error) {
        console.error(error)
      }
    }

    if (id !== null) fetchData()
  }, [id])

  return reviews
}

export default useReviews
