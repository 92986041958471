import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { colors, desktopBreakpoint, mobileVW, font } from '../../../styles'

// Hooks
import useDictionaryCtx from '../../../hooks/context/useDictionaryCtx'

// Components
import ReviewStars from './ReviewStars'

// =============== //
// ++ Helper(s) ++ //
// =============== //

function formatDate(dateString) {
  const [day, month, [, , ...year]] = new Intl.DateTimeFormat('nl-nl')
    .format(new Date(dateString))
    .split('-')

  return `${day}/${month}/${year}`
}

const Card = ({ review }) => {
  const dictionary = useDictionaryCtx()
  const textRef = useRef()

  const [showMore, setShowMore] = useState(false)
  const [textOverflow, setTextOverflow] = useState(false)

  useEffect(() => {
    if (!(textRef && textRef.current)) return

    const txt = textRef.current
    const css = getComputedStyle(txt)

    const lineHeight = Number(css.lineHeight.replace('px', ''))
    const lineClamp = Number(css['-webkit-line-clamp'])
    const height = txt.scrollHeight

    const lines = height / lineHeight

    if (lines > lineClamp) setTextOverflow(true)
  }, [])

  return (
    <Review>
      <ReviewHeader>
        <ReviewAuthor>{review.user.display_name}</ReviewAuthor>
        <ReviewDate>{formatDate(review.created_at)}</ReviewDate>
        <Stars rating={review.score} />
      </ReviewHeader>
      <ReviewContent>
        <ReviewTitle showMore={!textOverflow || showMore}>
          {review.title}
        </ReviewTitle>
        <ReviewText showMore={showMore} ref={textRef}>
          {review.content}
        </ReviewText>

        {textOverflow && (
          <ShowMoreButton onClick={() => setShowMore(!showMore)}>
            {showMore ? dictionary.readLess : dictionary.readMore}
          </ShowMoreButton>
        )}
      </ReviewContent>
    </Review>
  )
}

const Review = styled.article`
  padding: ${mobileVW(30)} ${mobileVW(30)} ${mobileVW(37)};
  background: ${colors.beigeUltraLight};
  border-radius: ${mobileVW(6)};
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: ${mobileVW(10)};
    height: ${mobileVW(10)};
    border-radius: ${mobileVW(3)} 0 0 0;
    background: ${colors.beigeUltraLight};
    left: ${mobileVW(19)};
    top: ${mobileVW(-5)};
    transform: rotate(45deg);

    @media (min-width: ${desktopBreakpoint}) {
      width: 12px;
      height: 12px;
      border-radius: 3px;
      left: 29px;
      top: -5px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 36px 49px 31px 62px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
  }
`

const ReviewHeader = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: ${mobileVW(55)};
  align-content: space-between;
  margin-bottom: ${mobileVW(12)};

  @media (min-width: ${desktopBreakpoint}) {
    height: auto;
    margin-bottom: 0;
  }
`

const ReviewAuthor = styled.h5`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  margin: 0;
  line-height: ${mobileVW(27)};
  margin-bottom: ${mobileVW(4)};
  text-transform: lowercase;
  color: ${colors.brownDark};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 36px;
    line-height: 27px;
    margin-bottom: 9px;
  }
`

const ReviewDate = styled.p`
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  font-family: ${font.larsseit};
  color: ${colors.brownDark};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Stars = styled(ReviewStars)`
  height: ${mobileVW(24)};
  margin: 0;
  margin-top: ${mobileVW(3)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 11px;
    height: 25px;
    margin-left: -5px;
  }
`

const ReviewContent = styled.div`
  width: 100%;
  max-width: 670px;
`

const ReviewTitle = styled.h4`
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: ${mobileVW(22)};
  line-height: ${mobileVW(22)};
  margin: 0;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 14px;
  }

  @media (max-width: ${desktopBreakpoint}) {
    ${({ showMore }) =>
      showMore
        ? `margin-bottom: 3px;`
        : `
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          height: ${mobileVW(25)};
          line-height: ${mobileVW(25)};
    `}
  }
`

const ReviewText = styled.p`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  color: ${colors.brownDarkest};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 17px;
  }

  ${({ showMore }) =>
    !showMore &&
    `
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    @media (min-width: ${desktopBreakpoint}) {
      -webkit-line-clamp: 5;
    }
  `}
`

const ShowMoreButton = styled.span`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  color: ${colors.brownDarkest};
  text-transform: uppercase;
  text-decoration: underline;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 17px;
  }
`

export default Card
