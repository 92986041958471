import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { font, colors } from '../../styles'

// Components
import RichText from './RichText'
import ArrowFlat from '../graphics/ArrowFlat'

export const Accordion = ({ title, content, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const innerBodyRef = useRef(null)
  const outerBodyRef = useRef(null)

  useEffect(() => {
    if (!innerBodyRef.current || !outerBodyRef.current) return

    const outer = outerBodyRef.current
    const inner = innerBodyRef.current

    const { height } = inner.getBoundingClientRect()

    outer.style.height = isOpen ? `${height}px` : '0px'
  }, [isOpen])

  return (
    <AccordionItem className={`${isOpen && 'accordion--open'}`}>
      <AccordionInner>
        <AccordionItemHeading onClick={() => setIsOpen(!isOpen)}>
          {title} <ArrowFlat color='#C8C8C8' />
        </AccordionItemHeading>
        <AccordionItemContent ref={outerBodyRef}>
          <AccordionItemInnerContent ref={innerBodyRef}>
            {children ? children : <RichText json={content.json} />}
          </AccordionItemInnerContent>
        </AccordionItemContent>
      </AccordionInner>
    </AccordionItem>
  )
}

export const AccordionList = ({ accordions }) => {
  return (
    <List>
      {accordions.map((accordionData, i) => (
        <Accordion {...accordionData} key={i} />
      ))}
    </List>
  )
}

const List = styled.ul`
  margin-top: 16px;
`

const AccordionItem = styled.li`
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  padding: 19px 0;

  &.accordion--open h3 svg {
    transform: scale(-1);
  }
`

const AccordionItemHeading = styled.h3`
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: 20px;
  line-height: 21px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2px;
  font-weight: normal;

  svg {
    transition: transform 0.3s ease;
  }

  &:hover {
    cursor: pointer;
  }
`

const AccordionItemContent = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
`

const AccordionInner = styled.div``

const AccordionItemInnerContent = styled.div`
  padding-top: 19px;
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: 14px;
  line-height 1.22;

  > *:not(:last-child) {
    margin-bottom: 1em;
  }
  
  li {
    margin: .5em 0;
  }

  ul {
    list-style: outside;
    margin-left: 1.2em;

    ul {
      padding-left: 1em;
      list-style-type: circle;
    }
  }  
 `

export default AccordionList
