import React from 'react'
import styled from 'styled-components'
import {
  font,
  headerHeight,
  desktopBreakpoint,
  mobileVW,
} from '../../../styles'

// Hooks
import useDictionaryCtx from '../../../hooks/context/useDictionaryCtx'

// Components
import ProductForm from './Form'
import ProductImageSlider from './Slider'
import ReviewStars from '../../product/ProductReviews/ReviewStars'
import AccordionList from '../../shared/AccordionList'

const ProductDetails = ({
  images,
  descriptionShort,
  title,
  shopifyProduct: { handle },
  accordions,
  sizeGuideButtonText,
  sizeGuideContent,
  reviews: { bottomline },
  discountCampaign,
  brand = 'cycle',
}) => {
  const dictionary = useDictionaryCtx()

  return (
    <Container>
      <ProductImageSlider images={images} />
      <Details>
        <ProductVendor>{brand}</ProductVendor>
        <ProductTitle>{title}</ProductTitle>
        <ProductSubTitle>{descriptionShort}</ProductSubTitle>
        <ProductRating>
          <ProductStars rating={bottomline?.average_score} />

          <a href='#reviews'>{dictionary.seeReviews}</a>
        </ProductRating>

        <ProductForm
          handle={handle}
          sizeGuideContent={sizeGuideContent}
          sizeGuideButtonText={sizeGuideButtonText}
          discountCampaign={discountCampaign}
        />

        {accordions && <AccordionList accordions={accordions} />}
      </Details>
    </Container>
  )
}

const Container = styled.div`
  margin-top ${headerHeight.mobile}px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${mobileVW(66)};
  max-width: 1540px;

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${headerHeight.desktop + 122}px;;
    display: grid;
    grid-template-columns: auto 369px;
    grid-gap: 77px;
    padding-left: 114px;
    padding-right: 139px;
    align-items: flex-start;
    margin-bottom: 125px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
`

const Details = styled.div`
  padding: 14px 20px 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin-top: -19px;
  }
`

const ProductVendor = styled.p`
  text-transform: uppercase;
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(17)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 16.8px;
  }
`

const ProductTitle = styled.h1`
  text-transform: lowercase;
  font-family: ${font.ben};
  font-size: ${mobileVW(40)};
  line-height: ${mobileVW(38)};
  margin-bottom: ${mobileVW(6)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 40px;
    line-height: 38.4px;
    margin-bottom: 6px;
  }
`

const ProductSubTitle = styled.h2`
  font-family: ${font.larsseit};
  font-weight: normal;
  font-size: ${mobileVW(20)};
  line-height: ${mobileVW(24)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 20px;
    line-height: 24px;
    min-height: 27px;
  }
`

const ProductRating = styled.div`
  display: flex;
  margin-top: ${mobileVW(10)};
  margin-bottom: ${mobileVW(25)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 6px;
    margin-bottom: 29px;
  }

  a {
    font-family: ${font.larsseit};
    font-size: ${mobileVW(14)};
    text-decoration: underline;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 14px;
    }
  }
`

const ProductStars = styled(ReviewStars)`
  margin-right: ${mobileVW(10)};
  height: 19px;

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: 10px;
  }
`

export default ProductDetails
