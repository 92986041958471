import React, { useState } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper'
import { colors, desktopBreakpoint, mobileVW } from '../../../styles'
import 'swiper/swiper.min.css'

SwiperCore.use([Navigation, Thumbs, Pagination])

const Slider = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <SliderContainer>
      <ThumbsContainer>
        <Swiper
          direction={'vertical'}
          onSwiper={setThumbsSwiper}
          spaceBetween={5}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          slidesPerView='auto'
        >
          {images.map(({ fluid }, i) => (
            <SwiperSlide key={i}>
              <div className='thumb-image'>
                <ThumbImage fluid={fluid} alt='cycle care' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </ThumbsContainer>
      <SwiperContainer>
        <Swiper
          slidesPerView={1}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          autoHeight={true}
          pagination={true}
          loop={true}
          breakpoints={{
            1024: {
              autoHeight: false,
            },
          }}
        >
          {images?.map(({ fluid }, i) => {
            if (!fluid) return
            return (
              <SwiperSlide key={i}>
                <StyledImage fluid={fluid} alt='cycle care' />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperContainer>
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: grid;

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: 96px 468px;
    grid-gap: 17px;
    position: sticky;
    top: 100px;
  }
`

const ThumbsContainer = styled.div`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }

  .swiper-slide {
    width: 96px;
    height: 96px;

    .thumb-image {
      height: 100%;
      overflow: hidden;
      transition: transform 0.3s ease;
    }

    &.swiper-slide-thumb-active {
      border: solid 1px ${colors.brownDark};

      .thumb-image {
        transform: scale(0.9);
      }
    }
  }
`

const ThumbImage = styled(Image)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`

const SwiperContainer = styled.div`
  width: 100vw;

  @media (min-width: ${desktopBreakpoint}) {
    width: auto;
  }

  .swiper-container {
    width: auto;

    .swiper-pagination-bullets {
      display: flex;
      left: 23px;
      bottom: 18px;
      position: absolute;
      z-index: 2;

      @media (min-width: ${desktopBreakpoint}) {
        display: none;
      }

      .swiper-pagination-bullet {
        margin: 0;
        margin-right: 8px;
        background: none;
        border: solid 1px black;
        opacity: 1;
        height: 8px;
        width: 8px;
        border-radius: 100px;

        &.swiper-pagination-bullet-active {
          background: black;
          transform: scale(1.4);
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 40px;
      width: 40px;
      z-index: 100;
      display: none;

      @media (min-width: ${desktopBreakpoint}) {
        display: flex;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-slide {
      border-radius: 0;

      @media (min-width: ${desktopBreakpoint}) {
        padding: 0 20px;
        border-radius: initial;
      }
    }
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  height: ${mobileVW(336)};
  object-fit: cover;
  object-position: bottom;

  @media (min-width: ${desktopBreakpoint}) {
    height: 514px;
    border-radius: 6px;
  }
`

export default Slider
