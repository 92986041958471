import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { colors, desktopBreakpoint, mobileVW, font } from '../../../styles'

// Hooks
import useDictionaryCtx from '../../../hooks/context/useDictionaryCtx'

// Components
import ReviewStars from '../../product/ProductReviews/ReviewStars'

const Form = ({ productId, imageUrl, price, slug, name, open, onSubmit }) => {
  const widgetRef = useRef()
  const innerRef = useRef()
  const outerRef = useRef()
  const dictionary = useDictionaryCtx()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    review: '',
  })

  useEffect(() => {
    if (!innerRef.current || !outerRef.current) return

    // Init Yotpo
    if (Yotpo && yotpo && open) {
      new Yotpo.API(yotpo).refreshWidgets()
    }

    const inner = innerRef.current
    const outer = outerRef.current

    const { height } = inner.getBoundingClientRect()

    outer.style.height = `${open ? height : 0}px`
  }, [open])

  const handleSubmit = () => {
    const widget = widgetRef.current

    const formWrapper = widget.querySelector('.write-review-wrapper.write-form')
    formWrapper.style.display = 'block'

    const widgetStar = widget.querySelector(
      `.stars-wrapper .review-star[data-score='${formData.score}']`,
    )
    const widgetTitleInput = widget.querySelector("input[name='review_title']")
    const widgetContentInput = widget.querySelector(
      "textarea[name='review_content']",
    )
    const widgetNameInput = widget.querySelector("input[name='display_name']")
    const widgetEmailInput = widget.querySelector("input[name='email']")
    const widgetSubmit = widget.querySelector('.yotpo-submit')

    if (widgetStar) widgetStar.click()
    widgetTitleInput.value = formData.title || ''
    widgetContentInput.value = formData.review || ''
    widgetNameInput.value = formData.name || ''
    widgetEmailInput.value = formData.email || ''

    if (onSubmit && typeof onSubmit === 'function') onSubmit()

    setTimeout(() => widgetSubmit.click(), 100)
  }

  return (
    <Wrapper>
      <Container ref={outerRef}>
        <Inner ref={innerRef}>
          <Indicator>{dictionary.indicatesARequiredFieldReviewForm}</Indicator>

          <Field>
            <Label>{dictionary.scoreReviewForm}</Label>
            <Stars onSelect={score => setFormData({ ...formData, score })} />
          </Field>

          <Field>
            <Label htmlFor='reviewTitle'>{dictionary.titleReviewForm}</Label>
            <Input
              id='reviewTitle'
              type='text'
              value={formData.title}
              onChange={e =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </Field>

          <Field>
            <Label htmlFor='reviewReview'>{dictionary.reviewReviewForm}</Label>
            <Textarea
              id='reviewReview'
              value={formData.review}
              onChange={e =>
                setFormData({ ...formData, review: e.target.value })
              }
            />
          </Field>

          <NameEmailContainer>
            <Field>
              <Label htmlFor='reviewName'>
                {dictionary.useYourNameReviewForm}
              </Label>
              <Input
                type='text'
                id='reviewName'
                value={formData.name}
                onChange={e =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </Field>

            <Field>
              <Label htmlFor='reviewEmail'>{dictionary.emailReviewForm}</Label>
              <Input
                id='reviewEmail'
                type='email'
                value={formData.email}
                onChange={e =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </Field>
          </NameEmailContainer>

          <Submit type='button' onClick={handleSubmit}>
            {dictionary.submitReviewForm}
          </Submit>
        </Inner>
      </Container>

      <YotpoContainer
        ref={widgetRef}
        className='yotpo yotpo-main-widget'
        data-product-id={productId}
        data-price={price}
        data-currency='EUR'
        data-name={name}
        data-url={`www.cycle.care/nl/${slug}`}
        data-image-url={imageUrl}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Container = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 1s ease;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${mobileVW(42)};

  @media (min-width: ${desktopBreakpoint}) {
    padding-bottom: 60px;
    padding-top: 89px;
  }
`

const Indicator = styled.p`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(24)};
  margin-bottom: ${mobileVW(29)};
  height: ${mobileVW(24)};

  &::before {
    content: '*';
    color: ${colors.orange};
    font-size: ${mobileVW(24)};
    line-height: inherit;
    margin-right: 0.35em;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 24px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 33px;
    height: 24px;
  }
`

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${mobileVW(21)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 23px;
  }
`

const Label = styled.label`
  font-family: ${font.larsseit};
  line-height: ${mobileVW(24)};
  font-size: ${mobileVW(18)};
  margin-bottom: ${mobileVW(11)};
  height: ${mobileVW(24)};

  &::before {
    content: '*';
    color: ${colors.orange};
    font-size: ${mobileVW(24)};
    line-height: inherit;
    margin-right: 0.35em;

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 24px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    line-height: 24px;
    font-size: 18px;
    margin-bottom: 11px;
    height: 24px;
  }
`

const Stars = styled(ReviewStars)`
  height: ${mobileVW(30)};
  margin-top: -${mobileVW(5)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
    height: 37px;
  }
`

const Input = styled.input`
  appearance: none;
  height: ${mobileVW(50)};
  padding: 0 ${mobileVW(30)};
  font-size: ${mobileVW(14)};
  font-family: ${font.larsseit};
  border: solid 2px ${colors.beigeUltraLight};
  border-radius: 6px;

  @media (min-width: ${desktopBreakpoint}) {
    height: 50px;
    padding: 0 30px;
    font-size: 14px;
    border-radius: 6px;
  }
`

const Textarea = styled.textarea`
  appearance: none;
  font-size: ${mobileVW(14)};
  font-family: ${font.larsseit};
  height: ${mobileVW(100)};
  padding: ${mobileVW(13)} ${mobileVW(30)};
  border: solid 2px ${colors.beigeUltraLight};
  border-radius: 6px;
  resize: none;

  @media (min-width: ${desktopBreakpoint}) {
    height: 100px;
    padding: 13px 30px;
    font-size: 14px;
    border-radius: 6px;
  }
`

const Submit = styled.button`
  height: ${mobileVW(48)};
  width: ${mobileVW(200)};
  border-radius: 100px;
  background: ${colors.orangeLight};
  font-family: ${font.ben};
  text-transform: lowercase;
  color: ${colors.brownDarkest};
  margin: ${mobileVW(6)} auto 0;
  font-size: ${mobileVW(18)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
    height: 48px;
    width: 200px;
    margin: 20px 0 0 auto;
  }
`

const NameEmailContainer = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    justify-content: flex-end;

    & > div {
      width: 320px;
      margin-left: 38px;
    }
  }
`

const YotpoContainer = styled.div`
  margin-bottom: 32px;

  > .main-widget > *:not(.yotpo-messages) {
    position: absolute;
    height: 0;
    width: 0;
    pointer-events: none;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: -100;
  }
`

export default Form
