import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  desktopBreakpoint,
  colors,
  font,
  mobileVW,
  headerHeight,
} from '../../../styles'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../../../hooks/context'
import useYotpoWidget from '../../../hooks/useYotpoWidget'

// Components
import ReviewStars from '../../product/ProductReviews/ReviewStars'
import ReviewCard from '../../product/ProductReviews/ReviewCard'
import ReviewForm from '../../product/ProductReviews/Form'

// =============== //
// ++ Helper(s) ++ //
// =============== //

function paginate(array = [], number = 0) {
  return array.reduce(
    (acc, cur, i) => {
      acc[Math.floor(i / number)].push(cur)

      return acc
    },
    new Array(Math.ceil(array.length / number)).fill(null).map(() => []),
  )
}

const ProductReviews = ({
  slug,
  shopifyProduct,
  imageUrl,
  productId,
  reviews: { reviews: allReviews, bottomline },
}) => {
  const loaded = useYotpoWidget()
  const dictionary = useDictionaryCtx()
  const { reviewsPagination = 3 } = useLayoutCtx()

  const [positiveReview, setPositiveReview] = useState(null)
  const [negativeReview, setNegativeReview] = useState(null)

  const [reviews, setReviews] = useState(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [pages, setPages] = useState(null)

  const [formOpen, setFormOpen] = useState(false)

  useEffect(() => {
    const _reviews = [...allReviews]

    if (_reviews.length === 0) return
    if (_reviews.length > 2) {
      const positive = _reviews.shift()
      const negative = _reviews.pop()

      setNegativeReview(negative)
      setPositiveReview(positive)

      const paginated = paginate(_reviews, reviewsPagination)

      setReviews(paginated)
      setPages(new Array(paginated.length).fill(1).map((_, i) => i + 1))
    } else {
      setReviews([_reviews])
    }
  }, [allReviews])

  return (
    <Container className='reviews'>
      <ScrollHook id='reviews' />
      <Header>
        <Heading>{dictionary.headingReviews}</Heading>
        {bottomline && bottomline.total_review > 0 ? (
          <>
            <HeaderStars rating={bottomline.average_score} />
            <TotalReviews>
              {dictionary.basedOnReviews.replace(
                '{reviews}',
                bottomline.total_review,
              )}
            </TotalReviews>
          </>
        ) : (
          <>
            <HeaderStars rating={0} />
            <TotalReviews>{dictionary.noReviewsYetReviews}</TotalReviews>
          </>
        )}

        {loaded && (
          <FormToggler onClick={() => setFormOpen(true)}>
            {dictionary.writeAReviewReviews}
          </FormToggler>
        )}
      </Header>

      {loaded && (
        <ReviewForm
          productId={productId}
          imageUrl={imageUrl}
          price={shopifyProduct.variants.edges[0].node.price}
          name={shopifyProduct.title}
          slug={slug}
          open={formOpen}
          onSubmit={() => setFormOpen(false)}
        />
      )}

      <ReviewsList>
        {positiveReview && (
          <li className='positive'>
            <ReviewCard review={positiveReview} />
          </li>
        )}
        {negativeReview && (
          <li className='negative'>
            <Separator>{dictionary.mostPositiveAndNegativeReviews}</Separator>
            <ReviewCard review={negativeReview} />
          </li>
        )}

        {reviews &&
          reviews[currentPage].map(review => (
            <li key={review.id}>
              <ReviewCard review={review} />
            </li>
          ))}
      </ReviewsList>

      {pages && (
        <PaginationContainer>
          {currentPage > 0 && (
            <PaginationButton onClick={() => setCurrentPage(currentPage - 1)}>
              {dictionary.previousReviews}
            </PaginationButton>
          )}

          <PaginationList>
            {pages.map((page, i) => (
              <PaginationListItem
                key={page}
                onClick={() => setCurrentPage(i)}
                active={i === currentPage}
              >
                <PaginationPage>{page}</PaginationPage>
              </PaginationListItem>
            ))}
          </PaginationList>

          {currentPage < pages.length - 1 && (
            <PaginationButton onClick={() => setCurrentPage(currentPage + 1)}>
              {dictionary.nextReviews}
            </PaginationButton>
          )}
        </PaginationContainer>
      )}
    </Container>
  )
}

const Container = styled.section`
  padding: 0 ${mobileVW(16)};
  max-width: 1047px;
  margin: 0 auto;
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 56px;
    box-sizing: content-box;
  }
`

const ScrollHook = styled.span`
  display: block;
  position: absolute;
  height: ${headerHeight.mobile}px;
  bottom: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    height: ${headerHeight.desktop}px;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom ${mobileVW(15)};

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 33px;
  }
`

const Heading = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  text-transform: lowercase;
  line-height: ${mobileVW(39)};
  margin-bottom: ${mobileVW(28)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 36px;
    line-height: 39px;
    width: 100%;
    margin-bottom: 32px;
  }
`

const HeaderStars = styled(ReviewStars)`
  height: ${mobileVW(29)};
  margin: 0;
  margin-bottom: ${mobileVW(12)};

  @media (min-width: ${desktopBreakpoint}) {
    height: 37px;
    margin-bottom: 0;
  }
`

const TotalReviews = styled.p`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(24)};
  margin-bottom: ${mobileVW(36)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    margin-left: 50px;
  }
`

const FormToggler = styled.button`
  background: #e5edfe;
  height: ${mobileVW(48)};
  width: 100%;
  text-align: center;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: ${mobileVW(18)};
  margin-bottom: ${mobileVW(37)};
  border-radius: 100px;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    height: 48px;
    font-size: 18px;
    width: 350px;
    margin-bottom: 0;
    margin-left: auto;
  }
`

const ReviewsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-gap: ${mobileVW(13)};

  .positive + .negative {
    position: relative;
    margin-bottom: ${mobileVW(6)};

    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 19px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    grid-gap: 14px;
  }
`

const Separator = styled.div`
  position: absolute;
  top: ${mobileVW(-6.5)};
  left: 50%;
  border-radius: 100px;
  width: auto;
  line-height: ${mobileVW(48)};
  transform: translate(-50%, -50%);
  padding: 0 ${mobileVW(34)};
  background: white;
  margin: 0 auto;
  z-index: 1;
  font-size: ${mobileVW(18)};
  font-family: ${font.ben};
  text-align: center;
  border: solid 2px black;
  white-space: nowrap;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    line-height: 48px;
    padding: 0 34px;
    font-size: 18px;
    top: -7px;
  }
`

const PaginationContainer = styled.div`
  margin-top: ${mobileVW(24)};
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 26px;
  }
`

const PaginationButton = styled.button`
  color: ${colors.brownDark};
  font-size: ${mobileVW(16)};
  font-family: ${font.larsseit};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
  }
`

const PaginationList = styled.ol`
  display: flex;
  grid-column: 2 / 3;

  li:not(:last-child) {
    margin-right: 0.41em;
  }
`

const PaginationListItem = styled.li`
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  font-size: ${mobileVW(16)};
  color: ${colors.brownDark};
  font-family: ${font.larsseit};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
  }
`

const PaginationPage = styled.button`
  text-decoration: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
`

export default React.memo(ProductReviews)
