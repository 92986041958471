import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { mobileVW, colors, font, desktopBreakpoint } from '../../../styles'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../../../hooks/context'

const Info = styled.dl`
  display: flex;
  color: ${colors.orange};
  text-transform: uppercase;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  font-family: ${font.larsseit};
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;
  }
`

const InfoItemTitle = styled.dt`
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
`

const InfoItemValue = styled.dd`
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;

  &:not(:last-child) {
    padding-right: 16px;
    margin-right: 16px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      margin: auto 0;
      height: 3px;
      width: 3px;
      border-radius: 50%;
      transform: translateX(-50%);
      background: ${colors.orange};
    }
  }
`

// Helpers

function formatDate(_date, locale = 'nl-NL') {
  if (!_date) return null
  if (new Date(Date.now()) > new Date(_date)) return null

  const date = new Date(_date)

  const day = date.toLocaleString(locale, { day: 'numeric' })
  const month = date.toLocaleString(locale, { month: 'long' })
  const year = date.toLocaleString(locale, { year: 'numeric' })

  return `${day} ${month} ${year}`
}

function formatHours(_minutes) {
  const decimalHours = _minutes / 60
  const roundedHours = Math.floor(_minutes / 60)

  const hours = roundedHours - 24 < 0 ? roundedHours : roundedHours - 24
  const minutes = (decimalHours - roundedHours) * 60

  return `${hours < 10 ? 0 : ''}${hours.toFixed()}:${
    minutes < 10 ? 0 : ''
  }${minutes.toFixed()}`
}

function formatDuration(_date, _duration, _text) {
  if (!_date) return _text.replace('{duration}', _duration)

  const date = new Date(_date)

  if (new Date(Date.now()) > date) return _text.replace('{duration}', _duration)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const startTime = formatHours(hours * 60 + minutes)
  const endTime = formatHours(hours * 60 + minutes + _duration)

  return `${startTime} - ${endTime}`
}

const InfoItem = ({ title, value }) => (
  <>
    <InfoItemTitle>{title}</InfoItemTitle>
    <InfoItemValue>{value}</InfoItemValue>
  </>
)

const VideoInfo = ({ releaseDate, videoDuration, price = null }) => {
  const [date, setDate] = useState(null)
  const [duration, setDuration] = useState(null)

  const dictionary = useDictionaryCtx()
  const { node_locale } = useLayoutCtx()

  useEffect(() => {
    if (releaseDate) {
      setDate(formatDate(releaseDate, node_locale))
    }

    if (videoDuration) {
      setDuration(
        formatDuration(
          releaseDate,
          videoDuration,
          dictionary.videoDurationLabelAcademy,
        ),
      )
    }
  }, [])

  return (
    <Info>
      {date && <InfoItem title='release date' value={date} />}
      {duration && <InfoItem title='duration' value={duration} />}
      {price === null && (
        <InfoItem title='price' value={price || dictionary.free} />
      )}
    </Info>
  )
}

export default VideoInfo
