import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { setDrawerOpen } from '../../../actions'
import {
  mobileVW,
  headerHeight,
  colors,
  font,
  desktopBreakpoint,
} from '../../../styles'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useAuth from '../../../hooks/useAuth'
import { useDictionaryCtx, useLayoutCtx } from '../../../hooks/context'
import useContentfulId from '../../../hooks/useContentfulId'

// Components
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import VideoInfo from './VideoInfo'
import AccordionList from '../../shared/AccordionList'
import ExpertLabel from '../../shared/ExpertLabel'
import Bookmark from '../../graphics/Bookmark'
import PlayIcon from '../../graphics/PlayIcon'
import IframeVideo from '../../shared/IframeVideo'

const Container = styled.section`
  margin-top: ${headerHeight.mobile}px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${mobileVW(13)};
  padding: ${mobileVW(16)} ${mobileVW(6)};
  margin-bottom: ${mobileVW(37)};
  max-width: 1168px;

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: 44fr 54fr;
    grid-auto-flow: column;
    align-items: flex-start;
    grid-gap: 25px;
    margin: ${headerHeight.desktop}px auto 0;
    padding: 55px 56px;
    box-sizing: content-box;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  border-radius: ${mobileVW(3)};
  overflow: hidden;
  position: relative;

  @media (min-width: ${desktopBreakpoint}) {
    position: sticky;
    top: ${headerHeight.desktop}px;
    grid-column: 2 / 3;
    height: auto;
    aspect-ratio: 632 / 448;
    border-radius: 6px;
    display: grid;
  }
`

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  overflow: hidden;
`

const VideoOverlayImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
  border-radius: inherit;
  overflow: hidden;
`

const VideoOverlayImage = styled(Image)`
  width: 100%;
  border-radius: inherit;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: ${({ available }) => (available ? 1 : 0.6)};
`

const VideoPlayButton = styled.button`
  position: relative;
  width: ${mobileVW(64)};
  height: ${mobileVW(64)};

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: 132px;
    height: 132px;
  }

  &:hover {
    cursor: pointer;

    svg {
      filter: brightness(200%);
    }
  }
`

const VideoAccountButtonStyle = css`
  padding: ${mobileVW(13)} ${mobileVW(16)};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(22)};
  font-family: ${font.ben};
  font-weight: normal;
  background: ${colors.orangeLight};
  border-radius: 100px;
  min-width: 190px;
  z-index: 2;
  text-transform: lowercase;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 13px 16px;
    font-size: 18px;
    line-height: 22px;
  }
`

const VideoAccountButton = styled.button`
  ${VideoAccountButtonStyle}

  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const VideoAccountButtonMobile = styled(Link)`
  ${VideoAccountButtonStyle}

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const VideoPlayIcon = styled(PlayIcon)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  opacity: 0.7;
  transition: filter 0.3s ease;
`

const Details = styled.div`
  background: rgba(238, 227, 218, 0.25);
  border-radius: ${mobileVW(6)};
  padding: ${mobileVW(17)} ${mobileVW(8)} ${mobileVW(40)} ${mobileVW(8)};

  @media (min-width: ${desktopBreakpoint}) {
    grid-column: 1 / 2;
    padding: 61px 51px 53px;
    border-radius: 6px;
  }
`

const DetailsHead = styled.header``

const DetailsBody = styled.div`
  display: flex;
  flex-direction: column;
`

const AcademyTitle = styled.h1`
  color: ${colors.brownDarkest};
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  text-transform: lowercase;
  font-weight: normal;
  margin-bottom: ${mobileVW(7)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 26px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${mobileVW(32)};
  padding: 0 ${mobileVW(6)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 20px;
    padding: 0;
    order: 1;
  }
`

const SaveButton = styled.button`
  text-transform: uppercase;
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  color: #000;
  display: flex;
  margin: 0;
  align-items: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;
  }

  &:not(:last-child) {
    margin-right: ${mobileVW(36)};

    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 36px;
    }
  }

  svg {
    width: ${mobileVW(14)};
    height: ${mobileVW(18)};
    margin-right: ${mobileVW(13)};

    @media (min-width: ${desktopBreakpoint}) {
      width: 14px;
      height: 18px;
      margin-right: 13px;
    }
  }
`

const AuthButton = styled(SaveButton)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
  }
`

const AuthButtonMobile = styled(SaveButton)`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const Experts = styled.div`
  margin-top: ${mobileVW(18)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 29px;
    order: 2;
    margin-bottom: 5px;
  }
`

const ExpertsListTitle = styled.p`
  text-transform: uppercase;
  color: ${colors.brownDark};
  font-family: ${font.larsseit};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  margin-bottom: ${mobileVW(2)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
  }
`

const ExpertsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${mobileVW(8)};

  @media (min-width: ${desktopBreakpoint}) {
    max-width: 333px;
    grid-row-gap: 8px;
  }
`

const AccordionsContainer = styled.div`
  padding: 0 ${mobileVW(6)};
  max-width: 324px;
  box-sizing: content-box;

  > ul > li {
    padding: ${mobileVW(28)} 0 ${mobileVW(17)};

    @media (min-width: ${desktopBreakpoint}) {
      padding: 25px 0 20px;
    }

    &:last-child {
      border: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    order: 3;
    max-width: 306px;
    box-sizing: border-box;
  }
`

const IFrameContainer = styled.div`
  border-radius: inherit;
  overflow: hidden;
  position: relative;

  * {
    border-radius: inherit;
    overflow: hidden;
  }
`

const VideoIframe = styled(IframeVideo)`
  background: black;
`

const AcademyDetails = ({
  id: productId,
  title,
  slug,
  image,
  description,
  releaseDateAcademy,
  videoDurationAcademy,
  video,
  experts,
  accordions,
}) => {
  const overlayRef = useRef()
  const { user, ready } = useAuth()
  const { node_locale, authenticationPage } = useLayoutCtx()
  const dictionary = useDictionaryCtx()
  const dispatch = useDispatch()

  const [savedProduct, setSavedProduct] = useState(false)

  const id = useContentfulId('product', productId)

  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .onSnapshot(doc => {
          if (doc.exists) {
            const { saved } = doc.data()

            setSavedProduct(saved)
          }
        })
    }
  }, [user])

  const saveProduct = () => {
    if (!savedProduct) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .set(
          {
            description,
            slug,
            title,
            image,
            id,
            saved: true,
          },
          { merge: true },
        )

      setSavedProduct(true)
    } else {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .delete()

      setSavedProduct(false)
    }
  }

  const hideOverlay = () => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.style.opacity = 0
      overlayRef.current.style.pointerEvents = 'none'
      overlayRef.current.style.zIndex = '-1'
    }
  }

  return (
    <Container>
      <VideoContainer>
        <VideoOverlay ref={overlayRef}>
          {ready && user ? (
            <VideoPlayButton onClick={() => hideOverlay()}>
              <VideoPlayIcon />
            </VideoPlayButton>
          ) : ready ? (
            <>
              <VideoAccountButton onClick={() => dispatch(setDrawerOpen(true))}>
                {dictionary.createAnAccountToWatchAcademy}
              </VideoAccountButton>
              <VideoAccountButtonMobile
                to={getPathname(node_locale, authenticationPage.slug)}
              >
                {dictionary.createAnAccountToWatchAcademy}
              </VideoAccountButtonMobile>
            </>
          ) : null}
          <VideoOverlayImageContainer>
            {video && video.imageThumbnail && (
              <VideoOverlayImage
                fluid={video.imageThumbnail.fluid}
                available={ready && user}
              />
            )}
          </VideoOverlayImageContainer>
        </VideoOverlay>

        {video && (
          <IFrameContainer>
            <VideoIframe
              videoSrcURL={video.videoLink}
              videoTitle={video.title}
            />
          </IFrameContainer>
        )}
      </VideoContainer>

      <Details>
        <DetailsHead>
          <AcademyTitle>{title}</AcademyTitle>
          <VideoInfo
            releaseDate={releaseDateAcademy}
            videoDuration={videoDurationAcademy}
          />
        </DetailsHead>

        <DetailsBody>
          {experts && Array.isArray(experts) && (
            <Experts>
              <ExpertsListTitle>{dictionary.givenByAcademy}</ExpertsListTitle>
              <ExpertsList>
                {experts.map(expert => (
                  <ExpertLabel key={expert.slug} {...expert} />
                ))}
              </ExpertsList>
            </Experts>
          )}

          <ButtonsContainer>
            {ready && user ? (
              <>
                <SaveButton onClick={() => saveProduct()}>
                  <Bookmark
                    fill={savedProduct ? 'black' : 'none'}
                    stroke='#000'
                  />
                  {dictionary.save}
                </SaveButton>
              </>
            ) : (
              <>
                <AuthButton onClick={() => dispatch(setDrawerOpen(true))}>
                  <Bookmark stroke='#000' />
                  {dictionary.save}
                </AuthButton>

                <AuthButtonMobile
                  to={getPathname(node_locale, authenticationPage.slug)}
                >
                  <Bookmark stroke='#000' />
                  {dictionary.save}
                </AuthButtonMobile>
              </>
            )}
          </ButtonsContainer>

          {accordions && (
            <AccordionsContainer>
              <AccordionList accordions={accordions} />
            </AccordionsContainer>
          )}
        </DetailsBody>
      </Details>
    </Container>
  )
}

export default AcademyDetails
