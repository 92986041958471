import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { setCartOpen } from '../../../actions'
import { font, colors, desktopBreakpoint, mobileVW } from '../../../styles'

// Hooks
import useReplaceLineItem from '../../../hooks/useReplaceLineItem'
import useShopifyProduct from '../../../hooks/useShopifyProduct'
import useDictionaryCtx from '../../../hooks/context/useDictionaryCtx'

// Components
import ProductOptions from './Options'
import Bag from '../../graphics/Bag'

// =============== //
// ++ Helper(s) ++ //
// =============== //

const formatPrice = price => (/\.\d{2}/.test(price) ? price : `${price}0`)

function getVariants(product) {
  const variants = product.variants.edges.map(({ node }) => {
    const id = node.id
    const available = node.availableForSale
    const options = node.selectedOptions.reduce(
      (acc, cur) => ({ ...acc, [cur.name]: cur.value }),
      {},
    )
    const price = node.price.amount

    return { id, available, options, price }
  })

  return variants
}

const Form = ({
  handle,
  sizeGuideButtonText,
  sizeGuideContent,
  discountCampaign,
}) => {
  const { product } = useShopifyProduct(handle)
  const [replaceLineItem, { loading }] = useReplaceLineItem()
  const dispatch = useDispatch()

  const dictionary = useDictionaryCtx()

  const optionsRef = useRef()

  const [variants, setVariants] = useState([])
  const [quantity, setQuantity] = useState(1)

  const [selectedVariant, setSelectedVariant] = useState(null)
  const [firstAvailableVariant, setFirstAvailableVariant] = useState(null)

  useEffect(() => {
    if (!product) return

    const _variants = getVariants(product)

    setVariants(_variants)

    if (_variants.length === 1) {
      setSelectedVariant(_variants[0])
    } else {
      setFirstAvailableVariant(_variants.find(variant => variant.available))
    }
  }, [product])

  const getButtonText = () => {
    if (loading) return dictionary.addToCartLoading

    if (selectedVariant) {
      if (selectedVariant.available) {
        return dictionary.addToCartProductPage.replace(
          '{price}',
          `€${formatPrice(selectedVariant.price)}`,
        )
      }

      return dictionary.unavailableVariant
    }

    if (firstAvailableVariant) {
      if (firstAvailableVariant.available) {
        return dictionary.addToCartProductPage.replace(
          '{price}',
          `€${formatPrice(firstAvailableVariant.price)}`,
        )
      } else {
        return dictionary.outOfStock
      }
    }

    return null
  }

  const AddProductToCart = async e => {
    e.preventDefault()

    if (!selectedVariant || selectedVariant.available == false) {
      // If optionsRef exists, the product has multiple variants
      if (optionsRef && optionsRef.current) {
        optionsRef.current.classList.remove('error')
        optionsRef.current.scrollIntoView()

        setTimeout(() => optionsRef.current.classList.add('error'), 0)
      }

      return
    }

    await replaceLineItem({
      variantId: selectedVariant.id,
      quantity,
    })

    dispatch(setCartOpen(true))

    if (typeof window !== 'undefined' && window.dataLayer && location) {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                slug: window.location.pathname,
                name: product.title,
                price: selectedVariant.price,
                quantity: 1,
                currency: 'EUR',
                brand: 'CYCLE',
                category: 'Product',
              },
            ],
          },
        },
      })
    }
  }

  return (
    <ProductForm onSubmit={AddProductToCart}>
      {variants.length > 1 && (
        <ProductOptions
          onChange={selected => setSelectedVariant(selected)}
          variants={variants}
          ref={optionsRef}
          guides={{
            ['Maat']: {
              content: sizeGuideContent,
              label: sizeGuideButtonText,
            },
          }}
        />
      )}

      <ControlContainer>
        <ControlDecrementButton
          type='button'
          onClick={() => quantity > 1 && setQuantity(quantity - 1)}
        />
        <ControlQuantity>{quantity}</ControlQuantity>
        <ControlIncrementButton
          type='button'
          onClick={() => setQuantity(quantity + 1)}
        />
      </ControlContainer>

      <AddToCartButtonContainer>
        <AddToCartButton
          disabled={
            loading || (selectedVariant && selectedVariant.available === false)
          }
        >
          <Bag />

          <span>{getButtonText()}</span>
        </AddToCartButton>

        {discountCampaign && (
          <AddToCartCampaign>{discountCampaign}</AddToCartCampaign>
        )}
      </AddToCartButtonContainer>
    </ProductForm>
  )
}

const ProductForm = styled.form`
  position: relative;
`

const ControlContainer = styled.div`
  display: grid;
  width: 96px;
  height: 37px;
  border-radius: 100px;
  grid-template-columns: 1fr 1fr 1fr;
  border: 2px solid ${colors.beigeUltraLight};
  overflow: hidden;
  margin-bottom: 18px;
`

const ControlButton = styled.button`
  position: relative;
  height: 100%;

  &::after,
  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 9px;
    height: 2px;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #000000;
  }
`

const ControlDecrementButton = styled(ControlButton)`
  &::after {
    display: block;
    left: 10px;
  }
`

const ControlIncrementButton = styled(ControlButton)`
  &::after,
  &::before {
    display: block;
    right: 10px;
  }

  &::before {
    transform: rotate(90deg);
  }
`

const ControlQuantity = styled.p`
  width: 100%;
  font-family: ${font.ben};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
`

const AddToCartButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  background: white;
  padding: ${mobileVW(10)} ${mobileVW(39)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    position: relative;
    margin: 0;
    background: none;
    z-index: 1;
  }
`

const AddToCartButton = styled.button`
  height: 50px;
  width: 100%;
  max-width: 297px;
  border-radius: 100px;
  background: ${colors.orangeLight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-size: 18px;
  line-height: 22px;
  color: #010101;
  font-weight: normal;

  svg {
    height: 24px;
    width: 24px;
  }

  span {
    margin-left: 14px;
  }
`

const AddToCartCampaign = styled.p`
  color: ${colors.orangeLight};
  font-family: ${font.larsseit};
  text-transform: uppercase;
  text-align: center;
  font-size: ${mobileVW(14)};
  margin-top: ${mobileVW(17)};
  max-width: 297px;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    margin-top: 17px;
  }
`
export default Form
