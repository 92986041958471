import React from 'react'
import styled from 'styled-components'
import { mobileVW, colors, font, desktopBreakpoint } from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import useLayoutCtx from '../../hooks/context/useLayoutCtx'

// Components
import Image from 'gatsby-image'
import { Link } from 'gatsby'

const Container = styled.div`
  position: relative;
  display: flex;
  height: ${mobileVW(45)};
  padding: 0 ${mobileVW(3)};
  border: 2px solid ${colors.beigeUltraLight};
  border-radius: 100px;
  align-items: center;
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    height: 45px;
    padding: 0 3px;
  }
`

const ImageContainer = styled.div`
  width: ${mobileVW(36)};
  height: ${mobileVW(36)};
  border-radius: 100%;
  overflow: hidden;

  @media (min-width: ${desktopBreakpoint}) {
    width: 36px;
    height: 36px;
  }
`

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`

const Title = styled.h5`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  font-weight: normal;
  margin: 0;
  margin-left: ${mobileVW(11)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    margin-left: 11px;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const ExpertLabel = ({ slug, title, image, jobFunction }) => {
  const { node_locale } = useLayoutCtx()

  return (
    <Container title={`${title} - ${jobFunction}`}>
      <ImageContainer>
        <StyledImage fluid={image.fluid} alt={image.alt} />
      </ImageContainer>

      <Title>
        {title} - {jobFunction}
      </Title>
      <StyledLink to={getPathname(node_locale, slug)} />
    </Container>
  )
}

export default ExpertLabel
