import React from 'react'

const PlayIcon = ({ color = '#C4C4C4', className }) => {
  return (
    <svg
      className={className}
      width='94'
      height='108'
      viewBox='0 0 94 108'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M91.5 49.6699C94.8333 51.5944 94.8333 56.4056 91.5 58.3301L7.49999 106.828C4.16666 108.752 -5.17309e-06 106.346 -5.00484e-06 102.497L-7.65062e-07 5.50257C-5.96817e-07 1.65357 4.16666 -0.752055 7.5 1.17245L91.5 49.6699Z'
        fill={color}
      />
    </svg>
  )
}

export default PlayIcon
