import React, { useEffect, useState } from 'react'

const useYotpoWidget = () => {
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `//staticw2.yotpo.com/${process.env.GATSBY_YOTPO_CLIENT_ID}/widget.js`
    script.async = true

    document.head.appendChild(script)

    script.onload = () => setHasLoaded(true)

    return () => {
      setHasLoaded(false)

      document.head.removeChild(script)
    }
  }, [])

  return hasLoaded
}

export default useYotpoWidget
