import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import Star from '../../graphics/Star'
import { desktopBreakpoint, colors } from '../../../styles'

const Stars = styled.div`
  position: relative;
  width: fit-content;
  height: 12px;
  display: flex;

  .stars {
    height: 100%;
  }

  .stars--full {
    position: absolute;
    top: 0;
    left: 0;
    clip-path: inset(0 ${({ rating }) => (1 - rating / 5) * 100}% 0 0);
  }

  @media (min-width: ${desktopBreakpoint}) {
    height: 30px;
  }
`

const StarsController = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1;
`

const StarButton = styled.button`
  flex-grow: 1;
  margin: 0;
  padding: 0;
  margin: 1px;
`

const ReviewStars = ({ rating: _rating = 0, className, onSelect = null }) => {
  const [rating, setRating] = useState(_rating)

  const handleClick = useCallback(
    e => {
      if (typeof onSelect !== 'function') return

      const starBtn = e.target.closest('button')

      if (starBtn && starBtn.dataset.score) {
        const score = parseInt(starBtn.dataset.score)

        setRating(score)
        onSelect(score)
      }
    },
    [onSelect],
  )

  useEffect(() => {
    setRating(_rating)
  }, [_rating])

  return (
    <Stars className={`reviews-stars ${className}`} rating={rating}>
      {onSelect != null && (
        <StarsController onClick={handleClick}>
          <StarButton data-score='1'></StarButton>
          <StarButton data-score='2'></StarButton>
          <StarButton data-score='3'></StarButton>
          <StarButton data-score='4'></StarButton>
          <StarButton data-score='5'></StarButton>
        </StarsController>
      )}
      <svg
        className='stars stars--full'
        viewBox='0 0 95 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill={colors.brownLight}
          d='M46.666 2.5668C46.9285 1.75888 48.0715 1.75888 48.334 2.5668L49.436 5.9584C49.5534 6.31971 49.8901 6.56434 50.27 6.56434H53.8361C54.6856 6.56434 55.0388 7.65139 54.3516 8.15071L51.4665 10.2468C51.1592 10.4701 51.0306 10.8659 51.148 11.2273L52.25 14.6189C52.5125 15.4268 51.5878 16.0986 50.9005 15.5993L48.0154 13.5032C47.7081 13.2799 47.2919 13.2799 46.9846 13.5032L44.0995 15.5993C43.4122 16.0986 42.4875 15.4268 42.75 14.6189L43.852 11.2273C43.9694 10.8659 43.8408 10.4701 43.5335 10.2468L40.6484 8.15071C39.9612 7.65138 40.3144 6.56434 41.1639 6.56434H44.73C45.1099 6.56434 45.4466 6.31971 45.564 5.9584L46.666 2.5668Z'
        />
        <path
          fill={colors.brownLight}
          d='M65.666 2.5668C65.9285 1.75888 67.0715 1.75888 67.334 2.5668L68.436 5.9584C68.5534 6.31971 68.8901 6.56434 69.27 6.56434H72.8361C73.6856 6.56434 74.0388 7.65139 73.3516 8.15071L70.4665 10.2468C70.1592 10.4701 70.0306 10.8659 70.148 11.2273L71.25 14.6189C71.5125 15.4268 70.5878 16.0986 69.9005 15.5993L67.0154 13.5032C66.7081 13.2799 66.2919 13.2799 65.9846 13.5032L63.0995 15.5993C62.4122 16.0986 61.4875 15.4268 61.75 14.6189L62.852 11.2273C62.9694 10.8659 62.8408 10.4701 62.5335 10.2468L59.6484 8.15071C58.9612 7.65138 59.3144 6.56434 60.1639 6.56434H63.73C64.1099 6.56434 64.4466 6.31971 64.564 5.9584L65.666 2.5668Z'
        />
        <path
          fill={colors.brownLight}
          d='M84.666 2.5668C84.9285 1.75888 86.0715 1.75888 86.334 2.5668L87.436 5.9584C87.5534 6.31971 87.8901 6.56434 88.27 6.56434H91.8361C92.6856 6.56434 93.0388 7.65139 92.3516 8.15071L89.4665 10.2468C89.1592 10.4701 89.0306 10.8659 89.148 11.2273L90.25 14.6189C90.5125 15.4268 89.5878 16.0986 88.9005 15.5993L86.0154 13.5032C85.7081 13.2799 85.2919 13.2799 84.9846 13.5032L82.0995 15.5993C81.4122 16.0986 80.4875 15.4268 80.75 14.6189L81.852 11.2273C81.9694 10.8659 81.8408 10.4701 81.5335 10.2468L78.6484 8.15071C77.9612 7.65138 78.3144 6.56434 79.1639 6.56434H82.73C83.1099 6.56434 83.4466 6.31971 83.564 5.9584L84.666 2.5668Z'
        />
        <path
          fill={colors.brownLight}
          d='M8.666 2.5668C8.92851 1.75888 10.0715 1.75888 10.334 2.5668L11.436 5.9584C11.5534 6.31971 11.8901 6.56434 12.27 6.56434H15.8361C16.6856 6.56434 17.0388 7.65139 16.3516 8.15071L13.4665 10.2468C13.1592 10.4701 13.0306 10.8659 13.148 11.2273L14.25 14.6189C14.5125 15.4268 13.5878 16.0986 12.9005 15.5993L10.0154 13.5032C9.70809 13.2799 9.29191 13.2799 8.98456 13.5032L6.09949 15.5993C5.41223 16.0986 4.48753 15.4268 4.75004 14.6189L5.85204 11.2273C5.96944 10.8659 5.84083 10.4701 5.53348 10.2468L2.64841 8.15071C1.96115 7.65138 2.31436 6.56434 3.16385 6.56434H6.73C7.1099 6.56434 7.4466 6.31971 7.564 5.9584L8.666 2.5668Z'
        />
        <path
          fill={colors.brownLight}
          d='M27.666 2.5668C27.9285 1.75888 29.0715 1.75888 29.334 2.5668L30.436 5.9584C30.5534 6.31971 30.8901 6.56434 31.27 6.56434H34.8361C35.6856 6.56434 36.0388 7.65139 35.3516 8.15071L32.4665 10.2468C32.1592 10.4701 32.0306 10.8659 32.148 11.2273L33.25 14.6189C33.5125 15.4268 32.5878 16.0986 31.9005 15.5993L29.0154 13.5032C28.7081 13.2799 28.2919 13.2799 27.9846 13.5032L25.0995 15.5993C24.4122 16.0986 23.4875 15.4268 23.75 14.6189L24.852 11.2273C24.9694 10.8659 24.8408 10.4701 24.5335 10.2468L21.6484 8.15071C20.9612 7.65138 21.3144 6.56434 22.1639 6.56434H25.73C26.1099 6.56434 26.4466 6.31971 26.564 5.9584L27.666 2.5668Z'
        />
      </svg>

      <svg
        onClick={handleClick}
        className='stars stars--empty'
        viewBox='0 0 95 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          stroke={colors.brownLight}
          d='M85.9865 2.67971L87.0885 6.07131C87.2548 6.58317 87.7318 6.92972 88.27 6.92972H91.8361C92.3317 6.92972 92.5377 7.56383 92.1368 7.85511L89.2518 9.95123C88.8163 10.2676 88.6341 10.8283 88.8005 11.3402L89.9025 14.7318C90.0556 15.2031 89.5162 15.595 89.1153 15.3037L86.2302 13.2076C85.7948 12.8912 85.2052 12.8912 84.7698 13.2076L81.8847 15.3037C81.4838 15.595 80.9444 15.2031 81.0975 14.7318L82.1995 11.3402C82.3659 10.8283 82.1837 10.2676 81.7482 9.95123L78.8632 7.8551C78.4623 7.56383 78.6683 6.92972 79.1639 6.92972H82.73C83.2682 6.92972 83.7452 6.58317 83.9115 6.07131L85.0135 2.67971C85.1666 2.20842 85.8334 2.20842 85.9865 2.67971Z'
          strokeWidth='0.730769'
        />
        <path
          stroke={colors.brownLight}
          d='M66.9865 2.67971L68.0885 6.07131C68.2548 6.58317 68.7318 6.92972 69.27 6.92972H72.8361C73.3317 6.92972 73.5377 7.56383 73.1368 7.85511L70.2518 9.95123C69.8163 10.2676 69.6341 10.8283 69.8005 11.3402L70.9025 14.7318C71.0556 15.2031 70.5162 15.595 70.1153 15.3037L67.2302 13.2076C66.7948 12.8912 66.2052 12.8912 65.7698 13.2076L62.8847 15.3037C62.4838 15.595 61.9444 15.2031 62.0975 14.7318L63.1995 11.3402C63.3659 10.8283 63.1837 10.2676 62.7482 9.95123L59.8632 7.8551C59.4623 7.56383 59.6683 6.92972 60.1639 6.92972H63.73C64.2682 6.92972 64.7452 6.58317 64.9115 6.07131L66.0135 2.67971C66.1666 2.20842 66.8334 2.20842 66.9865 2.67971Z'
          strokeWidth='0.730769'
        />
        <path
          stroke={colors.brownLight}
          d='M47.9865 2.67971L49.0885 6.07131C49.2548 6.58317 49.7318 6.92972 50.27 6.92972H53.8361C54.3317 6.92972 54.5377 7.56383 54.1368 7.85511L51.2518 9.95123C50.8163 10.2676 50.6341 10.8283 50.8005 11.3402L51.9025 14.7318C52.0556 15.2031 51.5162 15.595 51.1153 15.3037L48.2302 13.2076C47.7948 12.8912 47.2052 12.8912 46.7698 13.2076L43.8847 15.3037C43.4838 15.595 42.9444 15.2031 43.0975 14.7318L44.1995 11.3402C44.3659 10.8283 44.1837 10.2676 43.7482 9.95123L40.8632 7.8551C40.4623 7.56383 40.6683 6.92972 41.1639 6.92972H44.73C45.2682 6.92972 45.7452 6.58317 45.9115 6.07131L47.0135 2.67971C47.1666 2.20842 47.8334 2.20842 47.9865 2.67971Z'
          strokeWidth='0.730769'
        />
        <path
          stroke={colors.brownLight}
          d='M28.9865 2.67971L30.0885 6.07131C30.2548 6.58317 30.7318 6.92972 31.27 6.92972H34.8361C35.3317 6.92972 35.5377 7.56383 35.1368 7.85511L32.2518 9.95123C31.8163 10.2676 31.6341 10.8283 31.8005 11.3402L32.9025 14.7318C33.0556 15.2031 32.5162 15.595 32.1153 15.3037L29.2302 13.2076C28.7948 12.8912 28.2052 12.8912 27.7698 13.2076L24.8847 15.3037C24.4838 15.595 23.9444 15.2031 24.0975 14.7318L25.1995 11.3402C25.3659 10.8283 25.1837 10.2676 24.7482 9.95123L21.8632 7.8551C21.4623 7.56383 21.6683 6.92972 22.1639 6.92972H25.73C26.2682 6.92972 26.7452 6.58317 26.9115 6.07131L28.0135 2.67971C28.1666 2.20842 28.8334 2.20842 28.9865 2.67971Z'
          strokeWidth='0.730769'
        />
        <path
          stroke={colors.brownLight}
          d='M9.9865 2.67971L11.0885 6.07131C11.2548 6.58317 11.7318 6.92972 12.27 6.92972H15.8361C16.3317 6.92972 16.5377 7.56383 16.1368 7.85511L13.2518 9.95123C12.8163 10.2676 12.6341 10.8283 12.8005 11.3402L13.9025 14.7318C14.0556 15.2031 13.5162 15.595 13.1153 15.3037L10.2302 13.2076C9.7948 12.8912 9.2052 12.8912 8.76979 13.2076L5.88472 15.3037C5.48382 15.595 4.94441 15.2031 5.09754 14.7318L6.19954 11.3402C6.36586 10.8283 6.18366 10.2676 5.74825 9.95123L2.86318 7.8551C2.46228 7.56383 2.66831 6.92972 3.16385 6.92972H6.73C7.2682 6.92972 7.74519 6.58317 7.9115 6.07131L9.0135 2.67971C9.16663 2.20842 9.83337 2.20842 9.9865 2.67971Z'
          strokeWidth='0.730769'
        />
      </svg>
    </Stars>
  )
}

export default ReviewStars
